import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <Seo title="Page not found" />
    <div className="container max-width-sm padding-y-xxxl">
      <div className="text-component text-center">
        <h1>Page not found</h1>
        <p>Sorry, but the page you were looking for could not be found.</p>
        <p>
          <a href="/">Go to homepage</a> or{" "}
          <a href="/get-in-touch">get in touch</a>.
        </p>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
